import {
  COMMUNITY_USER_PREFERENCES_BASE_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserPreferences/Base';

export const COMMUNITY_USER_PREFERENCES_FRAGMENT = `
  fragment communityUserPreferencesFragment on CommunityUser {
    uid
    preferences: _preferences {
      ...communityUserPreferencesBaseFragment
    }
  }
  ${COMMUNITY_USER_PREFERENCES_BASE_FRAGMENT}
`;
